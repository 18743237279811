import '../index.css';
import React, { forwardRef }  from "react"
import FlipMove from 'react-flip-move';
import { useState, useEffect } from 'react'

import shuffle from 'lodash/shuffle';
import throttle from 'lodash/throttle';


let Rankslist = {
	["482936718635433984"] : ["Admiral","482936718635433984", "#0f9afc"],
	["482942543886024714"] : ["Vice Admiral","482942543886024714", "#44a1ae"],
	["482942783653412876"] : ["Commodore","482942783653412876", "#d532c2"],
	["482943282394038274"] : ["Captain","482943282394038274", "#e94ad7"],
	["485524025120915487"] : ["Commander","485524025120915487", "#2c61e2"],
	["485524025146081280"] : ["Lieutenant","485524025146081280", "#01dcff"],
	["485524025167314944"] : ["Midshipman","485524025167314944", "#13b3ee"],
	["513883237144330242"] : ["Warrant Officer","513883237144330242", "#13b385"],
	["486622480333537290"] : ["Chief Officer","486622480333537290", "#25c99c"],
	["486622477179551745"] : ["Petty Officer","486622477179551745","#42e0a5"],
	["486962967720493058"] : ["Ensign","486962967720493058","#8de7d4"],
	["486622426000785418"] : ["Seaman","486622426000785418","#ade2e2"],
	["486623117335330846"] : ["Recruit🎗","486623117335330846","#c9d65d"]
	//["492018186410000385"]  : ["Warden Veteran", "492018186410000385"]
  }

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
    
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)));
    if (hours == "00") {
        return minutes + 'm '
    }
    else if (minutes >= 0 && minutes <= 11) {
        return hours + ".1" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    else if (minutes >= 12 && minutes <= 17) {
        return hours + ".2" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 18 && minutes <= 23) {
        return hours + ".3" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    else if (minutes >= 24 && minutes <= 29) {
        return hours + ".4" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 30 && minutes <= 35) {
        return hours + ".5" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 36 && minutes <= 41) {
        return hours + ".6" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 42 && minutes <= 47) {
        return hours + ".7" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 48 && minutes <= 53) {
        return hours + ".8" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 54 && minutes <= 59) {
        return hours + ".9" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 60) {
        return hours + ".0" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    // else {
    // return hours+1+ 'h '
    // }
  }

//function Wartable(p) {
const Wartable = forwardRef((p, ref) => {
console.log(p.data, "COMPONENT")
let [ variable, setVariable ] = useState([]);



    return (
        
        //    <>
          <>
            <FlipMove>
           
                {p.data.map((user) => (
                   
                    <tr  key={user.ind}>

                        <td>{user.ind}</td>
                        <td >{msToTime(user.total)}</td>
                        <td style={{ color: "#d1d1d1", fontSize: "14px", fontStyle: "bold" }}>{user.name.slice(0, -5).length > 18 ? user.name.slice(0, -5).slice(0, -5) : user.name.slice(0, -5)}</td>
                        <td style={{ color: Rankslist[user.rank[0]][2] }} >{Rankslist[user.rank[0]][0]}</td>
                        <td ><code style={{ color: "#6a6a6a" }}>{new Date(Number(user.joined)).toDateString().split(' ').slice(1).join(' ')}</code></td>
                    </tr>
                    // </FlipMove>
                  
                ))}
                
                </FlipMove>
                {/* // </FlipMove> */}
           </>
        //   </>


            )
   })
   
   export default Wartable;