
export const timeStamps = (timespans, arg2) => {
   
    let Rankslist = {
        ["482936718635433984"]: ["Admiral", "482936718635433984"],
        ["482942543886024714"]: ["Vice Admiral", "482942543886024714"],
        ["482942783653412876"]: ["Commodore", "482942783653412876"],
        ["482943282394038274"]: ["Captain", "482943282394038274"],
        ["485524025120915487"]: ["Commander", "485524025120915487"],
        ["485524025146081280"]: ["Lieutenant", "485524025146081280"],
        ["485524025167314944"]: ["Midshipman", "485524025167314944"],
        ["513883237144330242"]: ["Warrant Officer", "513883237144330242"],
        ["486622480333537290"]: ["Chief Officer", "486622480333537290"],
        ["486622477179551745"]: ["Petty Officer", "486622477179551745"],
        ["486962967720493058"]: ["Ensign", "486962967720493058"],
        ["486622426000785418"]: ["Seaman", "486622426000785418"],
        ["486623117335330846"]: ["Recruit🎗", "486623117335330846"]
        //["492018186410000385"]  : ["Warden Veteran", "492018186410000385"]
    }
    let player;
    if (arg2 != undefined){

         player = [...arg2]

    }


    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let ids = []
    let dailyids = []
    let recruits = []
    let midrank = []
    let highrank = []
    let filtered = []
    let playersPerDay = []
    let days = []
    let weekdays = []
    let firstday = timespans[0][0]
    let now = timespans[timespans.length-1][0]

    while (firstday < now) {
        days.push(new Date(firstday).getDate())
        firstday += 86400000
        weekdays.push(new Date(firstday).toLocaleDateString(undefined, options).split(",")[0])
        
    }
    timespans.forEach((user, i, arr) => {
       
        let currentday = new Date(arr[i][0]).getDate()
        let nextday
        if (i == arr.length - 1) {
            nextday = false
        } else {
            nextday = new Date(arr[i + 1][0]).getDate()
        }

        if (currentday == nextday) {
            arr[i][1][0] == undefined ? filtered.push(0) : filtered.push(arr[i][1].length)
            ids.push(arr[i][1])
        } else {
            arr[i][1][0] == undefined ? filtered.push(0) : filtered.push(arr[i][1].length)
            let flatten = ids.flat()
            let set = new Set(flatten)
            set = [...set]
            let bufferrecruits = 0
            let buffermidrank = 0
            let bufferhighrank = 0

            if (player != undefined) {

                set.forEach(e => {
                   // console.log(e, player.findIndex(a => a.UID == e), player[player.findIndex(a => a.UID == e)])
                    if (player[player.findIndex(a => a.UID == e)] != undefined) {
                        if (player[player.findIndex(a => a.UID == e)].rank[0] == "486623117335330846" || player[player.findIndex(a => a.UID == e)].rank[0] == "486622426000785418"
                            || player[player.findIndex(a => a.UID == e)].rank[0] == "486962967720493058") {
                            bufferrecruits++
                        } else if (player[player.findIndex(a => a.UID == e)].rank[0] == "486622477179551745" || player[player.findIndex(a => a.UID == e)].rank[0] == "486622480333537290"
                            || player[player.findIndex(a => a.UID == e)].rank[0] == "513883237144330242" || player[player.findIndex(a => a.UID == e)].rank[0] == "485524025167314944"
                            || player[player.findIndex(a => a.UID == e)].rank[0] == "485524025146081280") {
                            buffermidrank++
                        } else {
                            bufferhighrank++
                        }
                    }
                })
             }
            ids = []
            recruits.push(bufferrecruits)
            midrank.push(buffermidrank)
            highrank.push(bufferhighrank)
            filtered.sort((a, b) => b - a)
            playersPerDay.push(filtered[0])
            filtered = []

            if (nextday != false && ((nextday - currentday) > 1)) {
                for (let i = 1; i < (nextday - currentday); i++) {
                    playersPerDay.push(0)
                }
            }
        }
    })

    if (playersPerDay.length > days.length) {
         playersPerDay.pop()

    }
   return [playersPerDay, days, weekdays, recruits, midrank, highrank]
 
}