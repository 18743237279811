import React from "react"
import '../index.css';
import { Bar } from "react-chartjs-2";

export const PlayersPD = React.memo(({ timespa, war }) => {

  return (
    <div className="tr_bg" style={{ backgroundColor: "#000000e0",  width: "inherit", maxHeight: "170px" }}>
      <Bar 
        data={timespa}
              options={
                {
                  plugins: {
                    title: {
                        display: false,
                        text: ``,
                        color: "#d1d1d1",
                        boxWidth: 1,
                          boxHeight: 1,
                    },
                    legend: {
                        title: {
                          color: "#d1d1d1",
                            text: "Recruits ⟶ Ensign",
                        },
                      labels :{
                          color: "#d1d1d1",
                          boxWidth: 10,
                          boxHeight: 10,
                      },
                        display: true,
                        color: "#143f60",
                        position: "top"
                    }
                },
               
                  categoryPercentage: 0.6, //bar thickness
                  barPercentage: 0.8,
                  scales: {
                   
                      x: {
                        barThickness: 2,  // number (pixels) or 'flex'
                      maxBarThickness: 3,
                          title: {
                            text: "Wars",
                            align:"start"
                          },
                       
                          ticks: {
                            // callback: function(value, index, ticks) {
                            //     return '$' + value;
                            // },
                            color: '#d1d1d1',
                              display: true,
                          }
                      },
                      y: {
                        
                        ticks: {
                          color: '#d1d1d1',
                            display: true,
                        }
                    }
                  },
                  responsive: false,
                  maintainAspectRatio: false,
                  indexAxis: 'x',
                 
              }}
      />
    </div>
  );
});
