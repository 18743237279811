import React from "react"
import { useState, useEffect } from 'react'
import '../index.css';
import { Line } from 'react-chartjs-2';

export const Timelines = React.memo(({ timespa }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(timespa.labels.length)

  if (length != timespa.labels.length) {
    setIsLoading(true)
    setLength(prev =>  timespa.labels.length  )
  }


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [isLoading])

  let loading = <>
   <div class="razar">
      <div class="ringbase ring1"></div>
      <div class="ringbase ring2"></div>
      <div class="pulse"></div>
      <div class="pointer">
        <div></div>
      </div>
      <div class="dot pos1"></div>
      <div class="dot pos2"></div>
  </div>
  </>

  return (
    <div className="graphschart" key={1} style={{ display:"inline-flex",position:"relative",height:"inherit", alignItems:"center",justifyContent:"space-around", borderRadius: "5px", position: "relative", width: "50%" }}>
      {isLoading ? loading :
        <Line
          data={timespa}
          options={{
            type: 'line',
            options: {
              elements: {
                point: {
                  radius: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },

                title: {
                  display: true,
                  text: 'Chart.js Line Chart'
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      }

    </div>
  );
})
