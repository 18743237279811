import '../index.css'
import '../profiles.css'
import React from "react"
import { useState, useEffect } from 'react'
import { Barshours } from './Barshours.js';
import uniform from '../uniform.jpg';
import { Rankslist, Rankarray } from './Rankslist.js'

export const MedalsProfiles = ({ charthours, playerData }) => {
    let medals = true

    const [loading, setLoading] = useState(true);
    const [animateName, setAnimateName] = useState("");
    const [durations, setDurations] = useState("");
    const [enlisted, setEnlisted] = useState("");
    const [promoted, setPromoted] = useState("");
    const [warEnlisted, setWarEnlisted] = useState("");
    const [medalsDb, setMedals] = useState("");
    const [hoveredMedal, setHoveredMedal] = useState(null);

    const handleHover = (id) => {
      setHoveredMedal( e => medalsDb.find(e => e.id == id))
    };
  
    const handleLeave = () => {
       setHoveredMedal(null);
    };

    useEffect(async () => {
        let request = await fetch('https://wardennavy.com/data/wars/medals', {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        })
        let data = await request.json()
       
       setMedals(data.sort((a,b) => b.createDate - a.createDate))
    }, [])

    // function hoursToMonthsDaysHours(milliseconds) {
    //     console.log(milliseconds)
    //     const msInHour = 3600000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
    //     const msInDay = 24 * msInHour; // 1 day = 24 hours
    //     const msInMonth = 30 * msInDay; // Assuming 1 month = 30 days

    //     // Calculate months, days, and hours
    //     const months = Math.floor(milliseconds / msInMonth);
    //     const remainingMilliseconds = milliseconds % msInMonth;
    //     const days = Math.floor(remainingMilliseconds / msInDay);
    //     const hours = Math.floor((remainingMilliseconds % msInDay) / msInHour);

    //     // Create the formatted string
    //     const result = [];
    //     if (months > 0) {
    //         result.push(`${months} ${months === 1 ? 'month' : 'months'}`);
    //     }
    //     if (days > 0) {
    //         result.push(`${days} ${days === 1 ? 'day' : 'days'}`);
    //     }
    //     if (hours > 0) {
    //         result.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
    //     }

    //     // Join the parts with commas and return the result
    //     return result.join(', ');
    // }
    function hoursToMonthsDaysHours(milliseconds, forParticipation) {

        const msInHour = 3600000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
        const msInDay = 24 * msInHour; // 1 day = 24 hours
        const msInMonth = 30 * msInDay; // Assuming 1 month = 30 days (adjust as needed)

        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Calculate months, days, and hours
        let months = 0;
        while (milliseconds >= msInMonth) {
            const daysInCurrentMonth = daysInMonth[months % 12];
            milliseconds -= daysInCurrentMonth * msInDay;
            months++;
        }

        const days = Math.floor(milliseconds / msInDay);
        milliseconds %= msInDay;
        const hours = Math.floor(milliseconds / msInHour);

        // Create the formatted string
        const result = [];
        if (months > 0) {
            result.push(`${months} ${months === 1 ? 'month' : 'months'}`);
        }
        if (days > 0) {
            result.push(`${days} ${days === 1 ? 'day' : 'days'}`);
        }
        if (forParticipation) {
            if (hours > 0) {
                result.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
            }
        }


        // Join the parts with commas and return the result
        return result.join(', ');
    }

    useEffect(() => {
        setLoading(true)
        setAnimateName(prev => "");
        setDurations(prev => "")
        setEnlisted(prev => "")
        setPromoted(prev => "")
        setWarEnlisted(prev => "")
        const reRender = async () => {
            let stop = playerData.username.lastIndexOf('#')
            for (let i = 0; i < playerData.username.length; i++) {
                if (i === stop) {
                    await new Promise(resolve => setTimeout(() => { resolve(); }, 200));
                    break
                }

                await new Promise(resolve =>
                    setTimeout(() => {
                        setAnimateName(e => e + playerData.username[i]);
                        resolve();
                    }, Math.random() < 0.5 ? 20 : 50)
                );
            }
            await new Promise(resolve =>
                setTimeout(() => {
                    setDurations(e => hoursToMonthsDaysHours(playerData.hours, true))
                 
                    setEnlisted(e => hoursToMonthsDaysHours(Date.now() - playerData.joined))
              
                    setWarEnlisted(e => playerData.allWars.find((e, i, arr) => {
                        if (arr[i + 1] !== undefined && playerData.joined > e.start && playerData.joined < arr[i + 1].start) {
                            return true
                        }
                        return playerData.joined < e.start
                    }))
                    let findPromotion = playerData.allWars.find(e => Number(e.warn) === Number(playerData.lastPromotion + 86))
                    setPromoted(e => playerData.lastPromotion == 0 ? 'before 86' : playerData.lastPromotion + 86)
                    resolve();
                }, 300)
            );
            setLoading(false)
        };

        if (playerData.username && charthours.datasets[0].data.length) {
            reRender()
        }

    }, [playerData.username]);


    //{`  ${playerData.username ? playerData.username.substring(0, playerData.username.lastIndexOf('#')) : null}`}
    //style={{ color: warEnlisted.winner == 'W' ?  "#44a5f7"  : warEnlisted.winner == 'C' ?  "#3ba528"  : "white"  }}
    return (
        <>
            {hoveredMedal
                ? <div style={{ display: 'inline-flex' }} className={'medal_modal'}>
                    <img alt="Uniform" style={{ width: "100px", height: 'fit-content' }} src={hoveredMedal.url}></img>
                    <div style={{ marginLeft: '20px', display: 'inline-flex', flexDirection: 'column' }}>
                        <h4 style={{ margin: '0px', color: 'white' }}>{hoveredMedal.title}</h4>
                        <p style={{ fontSize: '12px', color: '#9f9c9c', margin: '0px' }}>{`War ${hoveredMedal.war}`}</p>
                        <p style={{ color: '#dad8d8' }}>{hoveredMedal.description}</p>
                        <p style={{ fontSize: '12px', color: '#9f9c9c' }}>
                            {`Medal created by ${hoveredMedal.creator.nick}`}
                            <br />
                            {`Awarded to ${hoveredMedal.medalOwners.length} navymen`}
                        </p>

                    </div>

                </div>
                : null}

                
            {playerData && playerData !== undefined
                ? <>

                    <div style={{ marginTop: '10px', borderRadius: '5px', display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#000000e0', width: '100%' }}>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                                <div style={{ display: 'inline-flex' }}>
                                    <div style={{ height: '94px', width: '94px' }}>
                                        {!loading ? <img className={Rankslist[playerData.ranks[playerData.ranks.length - 1]][1] == "486623117335330846" ? 'stamp-animation' : 'slit-in-vertical'} style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={Rankslist[playerData.ranks[playerData.ranks.length - 1]][3]} alt="Description of your image" /> : null}
                                    </div>

                                    <div style={{ marginLeft: '15px', display: 'inline-flex', flexDirection: 'column' }}>
                                        {playerData.ranks ? <div style={{ fontSize: '20px', marginBottom: '0px', marginTop: '0px', color: Rankslist[playerData.ranks[playerData.ranks.length - 1]][2] }}>{`  ${Rankslist[playerData.ranks[playerData.ranks.length - 1]][0]}`}  <span style={{ marginTop: '0px', color: 'white' }}> {animateName}</span></div> : null}
                                        {!loading ? <>
                                            <div style={{ color: 'gray', fontSize: '14px', marginTop: '10px' }}>{'Rank since war: '}<span style={{ color: 'white' }}>{`${promoted}`}</span></div>
                                            <div style={{ color: 'gray', fontSize: '14px' }}>{'Enlisted: '}<span style={{ color: 'white' }}>{`${enlisted} ${enlisted ? 'ago' : ''}`}</span><span style={{ color: 'gray', fontSize: '14px' }}>{` during war: `}</span><span style={{ color: "white" }}>{`${warEnlisted ? warEnlisted.warn : ''}`}</span></div>
                                            <div style={{ color: 'gray', fontSize: '14px' }}>{`Participation: `}<span style={{ color: 'white' }}>{`${durations}`}</span></div>
                                        </> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* //https://cdn.discordapp.com/guilds/467433493261975563/users/309079101979754499/avatars/63c16105c17b858d296709d2490e1dbf.png */}
                        <div style={{ padding: '10px' }}>
                            <div style={{ height: '94px', width: '94px' }}>
                                {!loading ?
                                    playerData == undefined || playerData.avatar == undefined
                                        ? null
                                        : playerData.avatar.guild || playerData.avatar.global
                                            ? playerData.avatar.guild
                                                ? <img className={'slit-in-vertical'} alt="Uniform" width="auto" height="90" src={`https://cdn.discordapp.com/guilds/467433493261975563/users/${playerData.id}/avatars/${playerData.avatar.guild}.png`}></img>
                                                : <img className={'slit-in-vertical'} alt="Uniform" width="auto" height="90" src={`https://cdn.discordapp.com/avatars/${playerData.id}/${playerData.avatar.global}.png`}></img>
                                            : <img className={'slit-in-vertical'} alt="Uniform" width="auto" height="90" src={`https://cdn.discordapp.com/attachments/581579778612527136/924779880661528636/who_knows.png`}></img>
                                    : null}
                            </div>
                        </div>
                    </div>


                    {/* SECOND ROW */}
                    <div style={{ width: '100%', marginTop: '5px', display: 'inline-flex' }}>


                        {!loading
                            ? <div >
                                <div style={{ height: '330px' }}>
                                    {/* UNIFORM */}
                                    <img alt="Uniform" style={{ height: "inherit" }} src={Rankslist[playerData.ranks[playerData.ranks.length - 1]][4]}></img>


                                    {/* MEDALS*/}
                                    {/* <div style={{ position: 'relative', top: '-140px', left: '200px', width: '20px', height: '20px', borderRadius: '20px', backgroundColor: 'blue' }}></div> */}
                                   
                                

                                    {/* <div style={{ display: 'inline-flex',flexWrap: 'wrap', position: 'relative', top: '-193px', left: '108px', width: '217px', height: '134px' }}>
                            
                                       
                                        {medalsDb &&
                                            medalsDb
                                                .filter((e) => e.medalOwners.length && e.medalOwners.includes(playerData.id) && e.url.startsWith('https://')  ).slice(0, 13) 
                                                .map((e, i) => {
                                                let margin = '0px'
                                                if (i == 1 ) margin = '6px'
                                                if (i == 2 ) margin = '12px'
                                                if (i == 3 ) margin = '12px'
                                                if (i == 4 ) margin = '6px'

                                                return (
                                                    <div key={e.id} onMouseEnter={() => handleHover(e.id)}
                                                        onMouseLeave={handleLeave} style={{marginTop: margin, width:'30px', height:'60px', padding: '2px' }}>
                                                        <img alt="Uniform" className={'medal'} src={e.url} />
                                                    </div>
                                                )}
                                                )}
                                    </div> */}

                                </div>
                                <div style={{ fontSize: '20px', marginBottom: '0px',color: 'white', marginTop: '5px', padding: '4px', borderRadius: '5px'}} >  Medals  </div>
                                <div style={{marginTop: '5px', display: 'inline-flex', justifyContent: 'space-between', flexWrap: 'wrap', width: "inherit" }}>
                                 
                                    {medalsDb && medalsDb.length  && medalsDb.filter((e) => e.medalOwners.length && e.medalOwners.includes(playerData.id) && e.url.startsWith('https://')  ).map(e => (
                                        <div style={{ backgroundColor: '#000000e0' }} onMouseEnter={() => handleHover(e.id)}
                                        onMouseLeave={handleLeave}>
                                            {e.url.startsWith('https://')
                                                ? <img alt="Uniform" style={{ width: "40px" }} src={e.url}></img>
                                                : <div style={{ color: 'gray' }}> No image </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            : null
                        }


                        <div style={{ marginLeft: '5px', width: '100%' }}>
                            <div style={{ backgroundColor: '#000000e0' }} >
                                {!loading ? <Barshours medals={medals} charthours={charthours} /> : null}
                            </div>

                            {!loading ? <div style={{ marginTop: '5px', width: 'inherit', height: '800px', backgroundColor: 'black' }}></div> : null}
                        </div>

                    </div>
                </>
                : <>
                    <h2 style={{ color: 'white', marginTop: '10px' }}>Select a player</h2>
                    <h2 style={{ color: 'white', marginTop: '10px' }}>Warden Navy Medals</h2>
                    <div style={{ display: 'inline-flex', justifyContent: 'space-evenly', flexWrap: 'wrap', width: "112vh" }}>
                        {medalsDb && medalsDb.map(e => (
                            <div style={{ backgroundColor: '#000000e0' }} onMouseEnter={() => handleHover(e.id)}
                            onMouseLeave={handleLeave}>
                                {e.url.startsWith('https://')
                                    ? <img alt="Uniform" style={{ width: "40px" }} src={e.url}></img>
                                    : <div style={{ color: 'gray' }}> No image </div>}
                            </div>
                        ))}
                    </div>
                    {/* <img alt="Uniform" style={{ width: "112vh" }} src={'https://cdn.discordapp.com/attachments/556088536453873664/650015017172729896/unknown.png'}></img> */}
                </>
            }
        </>
    )
}


