import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

//import { BrowserRouter as Router } from 'react-router-dom';
//import { Routes } from './Routes';
//npm start  equal to 'development'
// npm run build to make a production
//To create a production build, use npm run build.
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
