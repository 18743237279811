import React from "react"
import '../index.css';
import { Bar } from "react-chartjs-2";

export const Warsduration = React.memo(({ duration }) => {
  return (
    <div className="graphs" id="hours" style={{ position: "relative", minHeight: "280px"}}> 
      <Bar 
        data={duration}
              options={{
                legend: {
                    fontColor: "#d1d1d1",
                 },
                  categoryPercentage: 0.5,
                  barPercentage: 0.9,
                  scales: {
                      x: {
                          title: {
                            text: "Wars",
                            align:"start"
                          },
                        stacked: false,
                          ticks: {
                            // callback: function(value, index, ticks) {
                            //     return '$' + value;
                            // },
                            color: '#d1d1d1',
                              display: true,
                          }
                      },
                      y: {
                        stacked: false,
                        ticks: {
                          color: '#d1d1d1',
                            display: true,
                        }
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  indexAxis: 'x',
                  plugins: {
                      title: {
                          display: true,
                          text: "Wars duration in Days",
                          color: "#d1d1d1",
                      },
                      legend: {
                          title: {
                            color: "#d1d1d1",
                              text: "aaaa",
                          },
                        labels :{
                            color: "#d1d1d1",
                            boxWidth: 1,
                            boxHeight: 1,
                        },
                          display: true,
                          color: "#143f60",
                          position: "top"
                      }
                  }
              }}
      />
    </div>
  );
});