import React from "react"
import '../index.css';
import { useState, useEffect } from 'react'
import { Bar } from "react-chartjs-2";

export const RankHours = React.memo(({ chartDatab }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(chartDatab.labels.length)

  if (length != chartDatab.labels.length) {
    setIsLoading(true)
    setLength(prev =>  chartDatab.labels.length  )
  }


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [isLoading])

  let loading = <>  <div class="razar">
    <div class="ringbase ring1"></div>
    <div class="ringbase ring2"></div>
    <div class="pulse"></div>
    <div class="pointer">
      <div></div>
    </div>
    <div class="dot pos1"></div>
    <div class="dot pos2"></div>
  </div></>
  return (

    <div id="hours" style={{ display: "inline-flex", position: "relative", alignItems: "center", justifyContent: "space-around", width: "50%", minHeight: "280px" }}>
   
      {isLoading ? loading :
        <Bar
          data={chartDatab}
          options={{
            legend: {
              fontColor: "#d1d1d1",
            },
            categoryPercentage: 0.5,
            barPercentage: 0.9,
            scales: {
              x: {
                title: {
                  text: "Wars",
                  align: "start",
                  stacked: true,
                },
                ticks: {
                  color: '#d1d1d1',
                  display: true,
                }
              },
              y: {
                stacked: true,
                ticks: {
                  color: '#d1d1d1',
                  display: true,
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'x',
            plugins: {
              title: {
                display: true,
                text: "Unique Ranks per Day",
                color: "#d1d1d1",
              },
              legend: {
                title: {
                  color: "#d1d1d1",
                  text: "Recruits ⟶ Ensign",
                },
                labels: {
                  color: "#d1d1d1",
                  boxWidth: 10,
                  boxHeight: 10,
                },
                display: true,
                color: "#143f60",
                position: "top"
              }
            }
          }}
        />} 
    </div>
  );
});