export const msToTime = (duration) => {
    var milliseconds = Math.floor((duration % 1000) / 100),
    
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)));
    if (hours == "00") {
        return minutes + 'm '
    }
    else if (minutes >= 0 && minutes <= 11) {
        return hours + ".1" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    else if (minutes >= 12 && minutes <= 17) {
        return hours + ".2" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 18 && minutes <= 23) {
        return hours + ".3" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    else if (minutes >= 24 && minutes <= 29) {
        return hours + ".4" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 30 && minutes <= 35) {
        return hours + ".5" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 36 && minutes <= 41) {
        return hours + ".6" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 42 && minutes <= 47) {
        return hours + ".7" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 48 && minutes <= 53) {
        return hours + ".8" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 54 && minutes <= 59) {
        return hours + ".9" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    } else if (minutes >= 60) {
        return hours + ".0" + 'h '// + minutes + 'm ' //+ seconds + 's.' + milliseconds;
    }
    // else {
    // return hours+1+ 'h '
    // }
  }