import React from "react"
import Navbar from './components/Navbar.js';
import Stats from './components/Stats.js';
import Logindiv from './components/Logindiv.js';
import './App.css';
import './index.css';
import { useState, useEffect } from 'react'

let click = {
  target: {
    innerText: "Stats"
  },
  once: 0
}

const App = React.memo((p) => {

  const [tab, setTab] = useState("");
  const [clickEvent, setClickEvent] = useState(click);

  useEffect(() => {
    if (document.cookie == "") {
      setTab(true)
    } else {
      click.once = 1
      setTab(false)
      setClickEvent(click)
    }
  }, [])

  // if (document.cookie == "") {
  //   useEffect(() => {
  //     setTab(true)
  //   }, [])
  // } else if (click.once == 0) {
  //     click.once = 1
  //     setTab(false)
  //     setClickEvent(click)
  // }

  let stats = (e) => {
    click.once = 1
    setTab(false)
    setClickEvent(e)
  }
  // <Link className={styles.join} href="https://discord.gg/4N7C4MZu4d"> <div>Join</div></Link>
  return (
    <>
      {tab ? null : <Navbar stats={stats} recr={stats} timeline={stats} medals={stats} />}
      <div className="App">
        {tab ?
          <>
            <Logindiv />


          </>


          : <Stats click={clickEvent} />}
      </div>
    </>
  );
})

export default App;