export const Heathours = (timespans) => {

    let eachHour = []
    let playerPerHourtoSort = []
    let playerPerHour = []
    let heatmaparraybuffer = []
    let heatmaparray = []
    for (let i = 0; i < timespans[0].length; i++) {
        if (i != timespans[0].length) {
            playerPerHourtoSort.push(timespans[1][i])

            if (timespans[0][i] != timespans[0][i + 1]) {
                eachHour.push([timespans[0][i]])
                playerPerHourtoSort.sort((a, b) => b - a)
                playerPerHour.push([playerPerHourtoSort[0]])
                playerPerHourtoSort = []
            }
        }

    }

    if (eachHour[0] != 0) {
        let addto = Number(eachHour[0])
        for (let i = addto - 1; i >= 0; i--) {
            eachHour.unshift([i])
            playerPerHour.unshift([0])
        }
    }

    if (eachHour[eachHour.length - 1] != 23) {
        for (let i = eachHour[eachHour.length - 1][0] + 1; i < 24; i++) {

            eachHour.push([i])
            playerPerHour.push([0])
        }
    }
    for (let i = 0; i < 24; i++) {
        for (let j = i; j < playerPerHour.length; j += 24) {
            heatmaparraybuffer.push(Number(playerPerHour[j]))
        }
        heatmaparray.push(heatmaparraybuffer)
        heatmaparraybuffer = []
    }

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let filtered = []
    let playersPerDay = []
    let days = []
    let weekdays = []
    let firstday = timespans[0][0]
    let now = timespans[timespans.length - 1][0]

    while (firstday < now) {
        days.push(new Date(firstday).getDate())
        firstday += 86400000//day  1h3600000

    }
    days.pop()


    timespans.forEach((user, i, arr) => {
        let currentday = (arr[i][0])
        let nextday
        if (i == arr.length - 1) {
            nextday = false
        } else {
            nextday = arr[i + 1][0]
        }

        if (new Date(currentday).getHours() == new Date(nextday).getHours()) {
            arr[i][1][0] == undefined ? filtered.push(0) : filtered.push(arr[i][1].length)
        } else {
            arr[i][1][0] == undefined ? filtered.push(0) : filtered.push(arr[i][1].length)

            filtered.sort((a, b) => b - a)
            //console.log(filtered)
            playersPerDay.push([filtered[0]])
            filtered = []
            if (nextday != false && (Number(nextday - currentday) > 3600000)) {
                for (let i = 3600000; i < Number(nextday - currentday); i += 3600000) {
                    playersPerDay.push([0])
                }
            }
        }
    })


    let adjusted
    if (playersPerDay.length > days.length) {
        playersPerDay.pop()

    }

    let secondArr = []
    for (let i = 0; i < playersPerDay.length; i++) {
        if (i != 0 && playersPerDay.indexOf(playersPerDay[i]) % 24 == 0) {
            secondArr.push(playersPerDay.slice(i - 24, i))
        }
    }

    return [heatmaparray]

}