import '../index.css';
import React from "react"


export const Selectwar = React.memo((p) => {
if (p.num == undefined) return (<></>)
//console.log(p.num[0],p.num[1][p.num[1].length - 1].warn, p.num[1])
  return (
    <>
      <div className='wars_all_container'>
        <div style={{ display: "inline-flex", flexDirection: "column", textAlign: "center" }}>
          <label style={{ color: "#d5d5d5", fontWeight: 'bold' }} >ALL </label>
          <input onChange={p.unclick} style={{ size: "50" }} type="checkbox" name="myCheckbox" />
        </div>
        <div className='select_war' style={{ display: "inline-flex", margin: "10px" }}>
          <label style={{ color: "#d5d5d5", fontWeight: 'bold' }} >WAR </label>
          <select multiple="multiple" className="option" onChange={p.change}>
            {p.num[0] == p.num[1][0].warn ? null : <option selected="selected" className="options" >{p.num[0]}</option>}
            {(p.num[1].map(e => <option key={e.warn} className="list" style={  e.winner == 'W' ? { color: "#44a5f7" } : { color: "#3ba528" }} >{e.warn}</option> ))}
          </select>
        </div>
      </div>
    </>
  );
})

