export const Rankslist = {
	["482936718635433984"] : ["Admiral","482936718635433984", "#0f9afc", '/admiral.png', '/admiraluniform.jpg'],
	["482942543886024714"] : ["Vice Admiral","482942543886024714", "#44a1ae", '/viceadmiral.png','/ltuniform.jpg'],
	["482942783653412876"] : ["Commodore","482942783653412876", "#d532c2", '/commodore.png','/ltuniform.jpg'],
	["482943282394038274"] : ["Captain","482943282394038274", "#e94ad7", '/captain.png','/ltuniform.jpg'],
	["485524025120915487"] : ["Commander","485524025120915487", "#2c61e2", '/commander.png','/ltuniform.jpg'],
	["485524025146081280"] : ["Lieutenant","485524025146081280", "#01dcff", '/lieutenant.png','/ltuniform.jpg'],
	["485524025167314944"] : ["Ensign","485524025167314944", "#13b3ee", '/ensign.png','/uniform.jpg'],
	["513883237144330242"] : ["Warrant Off.","513883237144330242", "#13b385", '/warrantofficer.png','/uniform.jpg'],
	["486622480333537290"] : ["Chief Off.","486622480333537290", "#25c99c", '/chiefpettyofficer.png','/uniform.jpg'],
	["486622477179551745"] : ["Petty Off.","486622477179551745","#42e0a5", '/pettyofficer.png','/uniform.jpg'],
	["486962967720493058"] : ["Seaman","486962967720493058","#8de7d4", '/seaman.png','/cadetuniform.jpg'],
	["486622426000785418"] : ["Cadet","486622426000785418","#ade2e2", '/cadet.png','/cadetuniform.jpg'],
	["486623117335330846"] : ["Recruit🎗","486623117335330846","#c9d65d", 'https://cdn.discordapp.com/attachments/713386376825143313/936951574310314004/output-onlinepngtools.png', '/recruituniform2.jpg'],
	["923937298838585364"] : ["Left / Kicked","923937298838585364","#c9d65d"]
	//["492018186410000385"]  : ["Warden Veteran", "492018186410000385"]
  }

  export const Rankarray = ["486623117335330846","486622426000785418","486962967720493058","486622477179551745",
  "486622480333537290","513883237144330242","485524025167314944","485524025146081280",
  "485524025120915487","482943282394038274","482942783653412876","482942543886024714",
  "482936718635433984" ]