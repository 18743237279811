import React from "react"
import '../index.css';
import { Bar } from "react-chartjs-2";

export const Barwars = React.memo(({ chartDatab, onlydiv }) => {
  return (

    <div className={onlydiv ? "truediv" : "graphs"} id="hours" style={{ minHeight: "280px" }}>
      <Bar
        data={chartDatab}
        options={{
          legend: {
            fontColor: "#d1d1d1",
          },
          categoryPercentage: 0.5,
          barPercentage: 0.9,
          scales: {
            x: {
              title: {
                text: "Wars",
                align: "start",
                stacked: true,
              },

              ticks: {
                // callback: function(value, index, ticks) {
                //     return '$' + value;
                // },
                color: '#d1d1d1',
                display: true,
              }
            },
            y: {
              stacked: true,
              ticks: {
                color: '#d1d1d1',
                display: true,
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'x',
          plugins: {
            title: {
              display: true,
              text: "Ranks & Tot Playtime",
              color: "#d1d1d1",
            },
            legend: {
              title: {
                color: "#d1d1d1",
                text: "Recruits ⟶ Ensign",
              },
              labels: {
                color: "#d1d1d1",
                boxWidth: 10,
                boxHeight: 10,
              },
              display: true,
              color: "#143f60",
              position: "top"
            }
          }
        }}
      />
    </div>
  );
});