import React from "react"
import '../index.css';


export const Names = React.memo(({ nameslist, getPlayer }) => {
    return (
        <div style={{position: "absolute", borderRadius: "4px", padding: "16px", left: "27px", top: " 188px", backgroundColor: "#262626", width: "306px", color: "#cbcbcb", margin: "3px" }}> 
            {nameslist.length > 0 ? nameslist.map(e =>( <p key={e[1]} className="found_names" style={{margin: "3px"}} onMouseUp={evt => getPlayer(evt, e[1]) } >{e[0]}</p>)) : "No Matches"}
        </div>       
    )
})
